<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">VoIP</h3>
			<div class="col-3 d-flex align-items-center justify-content-between">
				<div>
					<a @click="downloadCDRs" style="cursor: pointer">
						<span class="svg-icon svg-icon-lg">
							<inline-svg src="/media/svg/icons/Files/Download.svg" />
						</span>
						Download CDRs
						<b-spinner v-if="loading" variant="secondary" label="Loading..." />
					</a>
				</div>
			</div>
		</div>
		<div class="card-body pt-2">
			<p v-if="tsip.currentcalls.length > 0">
				Current Calls
				<b-table :fields="ccfields" :items="tsip.currentcalls"></b-table>
			</p>
			<p>Last 10 Calls <b-table :fields="cdrfields" :items="tsip.last10calls"></b-table></p>
			<p>Failed Calls <b-table :fields="ffields" :items="tsip.failedcalls"></b-table></p>
			<p>SIP Registrations<b-table :fields="rfields" :items="tsip.DIDs"></b-table></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SiteVoip',
	props: ['name','id', 'tsip'],

	data() {
		return {
			loading: false,
			ccfields: [
				{ key: 'DATE_TIME', label: 'Call Start Time', sortable: true },
				{ key: 'ANI', label: 'Calling' },
				{ key: 'DNIS', label: 'Called' },
				{ key: 'duration', label: 'Duration' },
			],
			cdrfields: [
				{ key: 'CONNECT_DATE_TIME', label: 'Call Start Time', sortable: true },
				{ key: 'ANI', label: 'Calling' },
				{ key: 'DNIS', label: 'Called' },
				{ key: 'DESCRIPTION', label: 'Description' },
				{ key: 'QUANTITY', label: 'Duration' },
			],
			ffields: [
				{ key: 'DATE_TIME', label: 'Call Start Time', sortable: true },
				{ key: 'ANI', label: 'Calling' },
				{ key: 'DNIS', label: 'Called' },
				{ key: 'DESCRIPTION', label: 'Description' },
			],
			rfields: [
				{ key: 'DID', label: 'SIP Account', sortable: true },
				{ key: 'node', label: 'Server' },
				{ key: 'reg', label: 'Last Registration', sortable: true },
			],
		};
	},
	created() {},
	methods: {
		downloadCDRs() {
			this.loading = true;
			this.$http.get(`tsip/cdrs/site/${this.id}`).then((resp) => {
				let csv = 'data:text/csv;charset=utf-8,';
				csv += 'CONNECT_DATE_TIME,ANI,DNIS,ACTUAL_DURATION,DESCRIPTION\n';
				resp.data.data.forEach((value) => {
					csv +=
						value.CONNECT_DATE_TIME +
						',' +
						value.ANI +
						',' +
						value.DNIS +
						',' +
						value.ACTUAL_DURATION +
						',' +
						value.DESCRIPTION +
						'\n';
				});
				const data = encodeURI(csv);
				const link = document.createElement('a');
				link.setAttribute('href', data);
				link.setAttribute('download', this.name + ' VOIP CDRs.csv');
				link.click();
				this.loading = false;
			});
		},
	},
};
</script>

<style>
</style>